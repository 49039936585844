import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useIntl } from 'react-intl';
import { useHasMounted } from '@luigiclaudio/ga-baseline-ui/helpers';
import { DefaultTemplate } from '@luigiclaudio/ga-baseline-ui/template';
import { H1, H3 } from '@luigiclaudio/ga-baseline-ui/typography';
import { Container, Row, Col, SiteContentWrapper } from '@luigiclaudio/ga-baseline-ui/layout';
import SiteFooter from '../components/pageLayout/SiteFooter';

export default () => {
    const theme = useContext(ThemeContext);
    const hasMounted = useHasMounted();
    const renderBgColor = hasMounted ? theme.site.stage.backgroundColor : null;
    const intl = useIntl();

    return (
        <DefaultTemplate
            bgStart={renderBgColor}
            bgEnd={renderBgColor}
            content={
                <SiteContentWrapper>
                    <Container isFullHeight>
                        <Row isFullHeight>
                            <Col xxs={30}>
                                <H1>{intl.formatMessage({ id: '404.title' })}</H1>
                                <H3>{intl.formatMessage({ id: '404.subtitle' })}</H3>
                            </Col>
                        </Row>
                    </Container>
                </SiteContentWrapper>
            }
            footer={<SiteFooter />}
        />
    );
};
